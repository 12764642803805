<template>
  <teleport to="body">
    <!-- <div v-cloak><div class="loadingspinner"></div></div> -->
    <router-view  />
  </teleport>
</template>

<style lang="css">

  :root {
    --font-family: "Titillium Web", system-ui,-apple-system, "Segoe UI", "Roboto", "Ubuntu", "Cantarell", "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  }

  html, body {
    scroll-behavior: smooth;
    scroll-padding-top: 1rem;
  }

  /* [v-cloak] {
    height: 100vh;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  [v-cloak] .loadingspinner {
    pointer-events: none;
    width: 2.5em;
    height: 2.5em;
    border: 0.4em solid transparent;
    border-color: #eee;
    border-top-color: #3e67ec;
    border-radius: 50%;
    animation: loadingspin 1s linear infinite !important;
  }

  @keyframes loadingspin {
    100% {
      transform: rotate(360deg);
    }
  } */

  .show-on-mobile {
    display: none;
  }

  @media (max-width:768px) {
    .hide-on-mobile {
      display: none;
    }
    .show-on-mobile {
      display: block;
    }
  }

  /**
   * @requires "process.env.VUE_APP_CSS_COLOR" and "process.env.VUE_APP_CSS_COLOR_ALT"
   */
  /**
   * :root[data-color] body > nav:is(:nth-of-type(1), :nth-of-type(2)) {
   *   --custom-color: #fff;
   *   --background-color: var(--header-color, --background-color);
   *   --nav-border-color: rgba(255, 255, 255, 0.2);
   *   --primary: var(--custom-color, --secondary);
   *   --secondary: var(--custom-color, --secondary);
   *   --h1-color: var(--custom-color, --h1-color);
   *   --color: var(--custom-color, --color);
   *   --contrast-hover: var(--custom-color, --contrast-hover);
   *   --secondary-hover: var(--custom-color, --secondary-hover);
   *   --contrast: var(--custom-color, --contrast-hover);
   *   --icon-chevron: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(255, 255, 255)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
   *   --primary-hover: #1ab3e6;
   * }
   *
   * :root[data-color] body > nav:nth-of-type(1) {
   *   --background-color: var(--header-color-alt, --background-color);
   * }
   */
</style>
